$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });


$('.dropdown-toggle').on 'click', (e) ->
    $('.dropdown').removeClass 'open'

$('[data-toggle="tooltip"]').tooltip();

$('.auth-modal').modal
    backdrop: 'static'
    keyboard: false

$('.faq-collapse').on 'hide.bs.collapse show.bs.collapse', () ->
    $(this).parents('.panel').toggleClass('panel-green panel-red').find('.panel-heading a').toggleClass('faq-title-opened faq-title-closed')


$('.dropdown-menu').each (k, v) ->
    menu = $(this)
    if menu.hasClass('pull-center')
        menu.css('margin-right', menu.outerWidth() / -2);
    return

clock = $('.clock');
expires = clock.data('expires');

clock.countdown(expires).on 'update.countdown', (event) ->
  $this = $(this).html(event.strftime('' + '<span class="cd-box"><span class="cd-value">%-D</span> ' + '<span class="cd-unit">nap</span></span>' + '<span class="cd-box"><span class="cd-value">%H</span> ' + '<span class="cd-unit">óra</span></span>' + '<span class="cd-box"><span class="cd-value">%M</span> ' + '<span class="cd-unit">perc</span></span>'))
  return

$('.datepicker').datetimepicker
    format: "YYYY.MM.DD"

Dropzone.autoDiscover = false;

$('#photos-uploads').dropzone
    paramName: "photo",
    url: "/jelentkezes/feltoltes"
    maxFiles: 4
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#photos-previews"
    acceptedFiles: "image/*"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum négy képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#area_map-uploads').dropzone
    paramName: "area_map",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#area_map-previews"
    acceptedFiles: "image/*,application/pdf,.doc,.docx,.pdf"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#audience_poster-uploads').dropzone
    paramName: "audience_poster",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#audience_poster-previews"
    acceptedFiles: "image/*,application/pdf,*.pdf"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#meeting_photos-uploads').dropzone
    paramName: "meeting_photo",
    url: "/jelentkezes/feltoltes"
    maxFiles: 5
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#meeting_photos-previews"
    acceptedFiles: "image/*,application/pdf,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum öt képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#quotation_plants-uploads').dropzone
    paramName: "quotation_plant",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#quotation_plants-previews"
    acceptedFiles: "image/*,application/pdf,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#quotation_tools-uploads').dropzone
    paramName: "quotation_tool",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#quotation_tools-previews"
    acceptedFiles: "image/*,application/pdf,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#quotation_elements-uploads').dropzone
    paramName: "quotation_element",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#quotation_elements-previews"
    acceptedFiles: "image/*,application/pdf,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#area_photos-uploads').dropzone
    paramName: "area_photo",
    url: "/jelentkezes/feltoltes"
    maxFiles: 5
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#area_photos-previews"
    acceptedFiles: "image/*,application/pdf,*.pdf"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum öt képet tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#ownership_statement-uploads').dropzone
    paramName: "ownership_statement",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#ownership_statement-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#participation_statement-uploads').dropzone
    paramName: "participation_statement",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#participation_statement-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#fund_statement-uploads').dropzone
    paramName: "fund_statement",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#fund_statement-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#registration_statement-uploads').dropzone
    paramName: "registration_statement",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#registration_statement-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#constitution-uploads').dropzone
    paramName: "constitution",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#constitution-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#signature-uploads').dropzone
    paramName: "signature",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#signature-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('#other_doc-uploads').dropzone
    paramName: "other_doc",
    url: "/jelentkezes/feltoltes"
    maxFiles: 2
    maxFilesize: 5
    headers: {'X-CSRF-TOKEN':$('input[name=_token]').val(), 'app_id': $('input[name=_app_id]').val()}
    previewsContainer: "#other_doc-previews"
    acceptedFiles: "application/pdf,image/jpg,image/jpeg,image/tiff,.doc,.docx"
    addRemoveLinks: true
    dictCancelUpload: "Mégsem"
    dictRemoveFile: "Törlés"
    dictMaxFilesExceeded: "Maximum két dokumentumot tölthet fel."
    dictFileTooBig: "Maximum 5MB-os képet tölthet fel."
    init: () ->
        @.on 'removedfile', (file) ->
            $.ajax
                url: '/jelentkezes/torles/' + file.name,
                dataType: 'html'

$('.btn-dropzone').on 'click', (e) ->
    e.preventDefault();
    $(this).parents('.dz-clickable').click();

$('textarea').keyup (e) ->
    i = $(this)
    l = i.val().length
    ml = i.attr('maxlength')
    t = i.parents('.form-group').find('.chars')
    if ml
        l = ml-l;
        t.text(l)

$('.delete').on 'click', (e) ->
    e.preventDefault();
    link = $(this)
    swal {
        title: "Törlés"
        text: "Valóban törölni kívánja a csatolt fájlt?"
        type: "warning"
        showCancelButton: true
        cancelButtonText: "Mégsem"
        confirmButtonText: "Igen"
        closeOnConfirm: false
        }, (isConfirm) ->
        if isConfirm
            window.location = link.prop 'href'
        return

$.ready(
    setTimeout (->
        $('.media-tile').each (index) ->
            item = $(this)
            h = item.height()
            d = item.find('figcaption div')
            c = d.height()
            m = h / 2 - (c / 2)
            d.delay(500 * index).animate {
                'margin-top': m
                opacity: 1
            }, 500
            return
        return
    ), 500
);

if $(document).width() > 768
    $('.dropdown-toggle').removeAttr('data-toggle').on 'click', (e) ->
        e.preventDefault()

$(window).on 'resize', (e) ->
    if $(document).width() > 768
        $('.dropdown-toggle').removeAttr('data-toggle').on 'click', (e) ->
            e.preventDefault()
    else
        $('.dropdown-toggle').attr('data-toggle', 'dropdown')

# asbtn = $('.autosave')
# if asbtn.length
#   form = asbtn.parents('form')
#   nextbtn = form.find('button[name=next_step]')

#   setInterval (->
#     nextbtn.attr 'disabled', true
#     $.ajax
#       url: form.prop('action')
#       data: form.serialize()
#       method: 'post'
#       success: (response) ->
#         return
#     return
#   ), 2000

$('.submission').on 'click', (e) ->
    btn = $(this)
    if (! $('input[name=confirmed_submission]').val())
        e.preventDefault()
        swal {
            title: "Figyelem"
            text: "A pályázat végleges beküldésre kerül, azon további módosításokat nem tud végezni."
            html: true
            type: "info"
            timer: 5000
            showCancelButton: true
            cancelButtonText: "Később folytatom"
            confirmButtonText: "Véglegesítés"
            closeOnConfirm: true
            }, (isConfirm) ->
                if isConfirm
                    $('input[name=confirmed_submission]').val 1
                    btn.trigger 'click'
                else
                    $('.autosave').trigger 'click'
                return

msieversion = ->
    ua = window.navigator.userAgent
    msie = ua.indexOf("MSIE ")

    if msie > 0 or !!navigator.userAgent.match(/Trident.*rv\:11\./)
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)))
    else
        return false

$('.media-tile, .article-grid').on 'click', (e) ->
    if msieversion() <= 10
        link = $(this).find('a')
        if link.attr('target') == '_blank'
            window.open link.attr 'href'
        else
            window.location link.attr 'href'

$(document).on 'click', '.btn-row-delete', (e) ->
    table = $(this).parents 'table'
    $(this).parents('tr').remove()

    table.find('.btn-row-delete').first().hide()

    sums = {};

    if (table.attr('id') == 'table-program_expenses')
        $('#sum-total_amount, #sum-downpayment, #sum-requested').text('0');

        table.find('input').each (i, item) ->
            f = $(this).data 'sum'
            if f
                v = parseInt($(this).val(), 10) or 0
                c = parseInt($('#sum-' + f).text(), 10) or 0
                $('#sum-' + f).text c + v

    return

$(document).on 'click', '.add-row', (e) ->
    e.preventDefault();

    tb = $(this).parents('.rel').find('tbody')
    tr = tb.find('tr.template')
    max = parseInt(tr.attr('data-max'), 10)
    orig = parseInt(tr.attr('data-orig'), 10)
    next = parseInt(max + 1, 10)

    search = new RegExp(orig, 'g');
    n = tr.clone().removeClass('template hidden').html().replace search, next

    tb.append('<tr>' + n + '</tr>')
    tr.attr 'data-max', next
    return

$(document).on 'change', '.calc-plus, .calc-minus', (e) ->
    e.preventDefault()
    inp = $(this)
    tbl = inp.parents('table')
    row = inp.parents('tr')
    fields = row.find('.calc-plus, .calc-minus')
    result = row.find('.row-result')
    sel = inp.data 'sum'
    col_fields = tbl.find('[data-sum=' + sel + ']')
    sum_result = $('#sum-' + sel)
    rowtotal = 0
    coltotal = 0

    fields.each (i, item) ->
        if $(this).hasClass 'calc-plus'
            rowtotal = rowtotal + parseInt $(this).val(), 10
        else
            rowtotal = rowtotal - parseInt $(this).val(), 10

    col_fields.each (i, item) ->
        if $(this).val() > 0
            coltotal = coltotal + parseInt $(this).val(), 10

    result.val rowtotal
    sum_result.text coltotal
    totalAmount = parseInt($('#sum-total_amount').text(), 10)
    totalDownpayment = parseInt($('#sum-downpayment').text(), 10)
    $('#sum-requested').text(parseInt(totalAmount - totalDownpayment), 10)
